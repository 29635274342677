<template>
  <div class="container mx-auto my-auto flex flex-col items-center justify-center ">
    <div class="h-32 w-32" style="background-position: center; background-image:url('/icon.png'); background-size: cover;">
    </div>
    <div class="p-8 flex flex-col justify-center items-center">
        <div class="mt-2 flex items-center rounded-full border border-gray-700 bg-white py-2 px-3 pr-6">
            <img src="https://img.icons8.com/?size=512&id=52882&format=png" class="h-8 w-8 mr-2"/>
            <input v-model="user.email" class="bg-white py-2 px-4 w-full outline-none" placeholder="Логин"/>
        </div>
        <div class="mt-2 flex items-center rounded-full border border-gray-700 bg-white py-2 px-3 pr-6">
            <img src="https://img.icons8.com/?size=512&id=64776&format=png" class="h-8 w-8 mr-2"/>
            <input v-model="user.password" class="bg-white py-2 px-4 w-full outline-none" type="password" placeholder="Пароль"/>
        </div>
        <div class="mt-8">
            <input type="submit" @click="auth()" class="rounded-md py-3 px-16 text-white bg-orange-800 font-semibold shadow-md cursor-pointer active:bg-orange-700" value="Войти"/>
        </div>
        <div v-if="loginErrors.length > 0" class="mt-8">
            <div v-for="error, index in loginErrors" :key="index">{{ error }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'LoginView',
    data () {
        return {
            user: {
                email: null,
                password: null
            },
            loginErrors: []
        }
    },
    methods: {
        auth () {
            const response = this.postServerData('login', { data: this.user })
            response.then(response => {
                    if (response.data.item != null) {
                        const user = response.data.item
                        this.$store.commit('logIn', user.at)
                        this.$router.push({ name: 'dash' })
                    } else {
                        this.loginErrors = ['Такой пользователь не найден']
                        setTimeout(() => {
                            this.loginErrors = []
                        }, 1000)
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .finally(() => (this.is_loaded = true))
        }
    }
}
</script>
