<template>
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full pl-2 py-2">
            <div class="flex flex-row items-center justify-between w-11/12">
                <div class="flex flex-col">
                    <div class="text-2xl font-semibold" @click="chart_maximized = !chart_maximized">Сводка</div>
                    <div class="text-xs text-gray-500">{{ date_from_local  }} - {{ date_to_local }}</div>
                </div>
                <div class="flex flex-col justify-end items-end px-2" @click="menu_open = !menu_open">
                    <div class="text-2xl font-semibold">
                        <animated-number :value="net_balance_diff" :is-public="isPublicIncomes && isPublicExpenses" />
                    </div>
                    <div class="flex flex-col justify-end items-end text-end text-xs text-gray-500 pt-1">
                        <div class="h-2 rounded-full w-24 bg-emerald-500">
                            <div class="h-2 rounded-full bg-rose-500" :style="{'width': (net_balance_percent < 0 ? 100 : (100 - net_balance_percent)) + '%'}"></div>
                        </div>
                        <!-- <animated-number :value="net_balance_percent" :decimals=2  :is-public="true"/> -->
                    </div>
                </div>
            </div>
            <div>
                <div class="flex flex-row items-center justify-end w-12">
                    <div class="bg-gray-200 rounded-md p-2 active:bg-gray-500" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=256&id=21622&format=png" class="h-6 w-6"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="date_filter_open" class="flex flex-row justify-around items-center py-4">
            с <input v-model="date_from" @change="getData()" type="date" class="bg-white p-2 rounded-md w-2/5" />
            до <input v-model="date_to" @change="getData()" type="date" class="bg-white p-2 rounded-md w-2/5" />
        </div> -->
        <div class="fixed top-0 left-0 z-[80] w-full h-full overflow-hidden transition-all flex justify-end" :class="[ !menu_open ? 'translate-x-full': null ]">
            <div class="w-10/12  bg-white px-4 md:px-0 py-4 h-full">
                <div class="flex flex-row items-center justify-between w-full px-2 py-2">
                    <div class="text-lg font-semibold">Сводка</div>
                    <div class="bg-gray-200 hover:bg-gray-300 rounded-full p-2" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6 w-6"/>
                    </div>
                </div>
                <div>
                    <router-link :to="{name: 'reports_year'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Годовой отчет</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_create'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в расходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_create'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в доходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'piggybanks'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Копилки</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'stashes'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Долги</div>
                        </div>
                    </router-link>
                    <div class="flex items-center py-2 my-4 px-4">
                        <input type="checkbox" v-model="isPublicIncomes" class="h-8 w-8 mr-2" /> Показать доходы
                    </div>
                    <div class="flex items-center py-2 my-4 px-4">
                        <input type="checkbox" v-model="isPublicExpenses" class="h-8 w-8 mr-2" /> Показать расходы
                    </div>
                </div>
            </div>
        </div>
        <div v-show="is_loaded" class="container h-full pb-16">
            <div class="flex bg-white rounded-md px-4 py-2 my-4 justify-between overflow-x-auto transition-all" id="chartWrap"
                :class="[ chart_maximized ? 'h-48' : 'h-32']">
                <div v-for="point,index in dataYear" :key="index" class="h-full flex flex-col items-center justify-center mx-1">
                    <div class="h-full flex items-end relative w-12">
                        <div class="w-12 rounded-t-sm bg-green-400 transition-all delay-350 border-t flex items-end" style="min-height: 1%"
                            :style="{ 'height': parseFloat((point.incomes.summ.raw / dataYearMeta.max) * 100).toFixed(2) +  '%' }">
                            <div class="w-full transition-all h-full">
                                <div class="w-full bg-green-300"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[0][0] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-green-400"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[1][0] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-green-500"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[0][1] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-green-600"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[1][1] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                            </div>
                        </div>
                        <div class="w-12 rounded-t-sm bg-amber-200 transition-alll delay-350 border-t flex items-end" style="min-height: 1%"
                            :style="{ 'height': parseFloat((point.expenses.summ.raw / dataYearMeta.max) * 100).toFixed(2) +  '%' }">
                            <div class="w-full transition-all h-full">
                                <div class="w-full bg-amber-300"
                                    :style="{ 'height': parseFloat(point.expenses.summ.types[0] * 100 / point.expenses.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-amber-600"
                                    :style="{ 'height': parseFloat(point.expenses.summ.types[1] * 100 / point.expenses.summ.raw).toFixed(2) + '%' }"></div>
                            </div>
                        </div>
                        <!-- {{ point.expenses.summ }} -->
                    </div>
                    <div class="text-sm p-1 rounded-sm cursor-pointer w-full text-center"
                        :class="[ point.incomes.summ.raw > point.expenses.summ.raw ? 'bg-green-100 text-green-700' : 'bg-rose-100 text-rose-700']">
                        {{ point.meta.year }}
                    </div>
                </div>
            </div>
            <div v-for="data, index in dataYear" :key="index" class="flex flex-row items-start justify-between my-16">
                <div class="w-1/3">
                    <div class="text-right text-8xl font-semibold text-stone-900 mt-[-10px] mb-4">{{  data.meta.year.replace('20', '`')  }}</div>
                    <div class="text-right font-semibold text-2xl" :class="{'text-green-700': data.incomes.summ.raw - data.expenses.summ.raw > 0}">{{  new Intl.NumberFormat('ru-RU').format(data.incomes.summ.raw - data.expenses.summ.raw)  }}</div>
                </div>
                <div class="text-right w-1/3">
                    <div class="font-semibold">
                        +{{  data.incomes.summ.formatted  }}
                    </div>
                    <div class="text-xs">
                        активно
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format(data.incomes.summ.types[0][0] + data.incomes.summ.types[0][1]) }}
                    </div>
                    <div class="text-xs">
                        пассивно
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format(data.incomes.summ.types[1][0] + data.incomes.summ.types[1][1])  }}
                    </div>
                    <div class="h-2"></div>
                    <div class="text-xs">
                        активно
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format(((data.incomes.summ.types[0][0] + data.incomes.summ.types[0][1])/12).toFixed(0)) }}
                    </div>
                    <div class="text-xs">
                        пассивно
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format(((data.incomes.summ.types[1][0] + data.incomes.summ.types[1][1])/12).toFixed(0))  }}
                    </div>
                </div>
                <div class="text-right w-1/3">
                    <div class="font-semibold">
                        -{{  data.expenses.summ.formatted  }}
                    </div>
                    <div class="text-xs">
                        операционка
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format(data.expenses.summ.types[1])  }}
                    </div>
                    <div class="text-xs">
                        остальное
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format(data.expenses.summ.types[0])  }}
                    </div>
                    <div class="h-2"></div>
                    <div class="text-xs">
                        операционка
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format((data.expenses.summ.types[1] / 12).toFixed(0))  }}
                    </div>
                    <div class="text-xs">
                        остальное
                        <br/>
                        {{  new Intl.NumberFormat('ru-RU').format((data.expenses.summ.types[0] / 12).toFixed(0))  }}
                    </div>
                </div>
                <!-- {{  data  }} -->
            </div>
        </div>
    </div>
</template>

<script>
// import CirclePlot from '@/components/CirclePlot.vue'
// import GraphPlot from '@/components/GraphPlot.vue'
import AnimatedNumber from '@/components/AnimatedNumber.vue'

export default {
    components: {
        // CirclePlot,
        // GraphPlot,
        AnimatedNumber
    },
    data () {
        return {
            meta: null,
            menu_open: false,
            is_loaded: false,
            dataYear: null,
            dataYearMeta: null,
            windowWidth: window.innerWidth
        }
    },
    methods: {
        getDataYear () {
            const response = this.getServerData('get_report_year', ['all', this.date_from, this.date_to])
            response.then(response => {
                                this.dataYear = response.data.data
                                this.dataYearMeta = response.data.meta
                                // this.meta = response.data.meta
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => {
                                this.is_loaded = true
                                this.selected_month_index = 0
                                const chartWrap = this.$el.querySelector('#chartWrap')
                                chartWrap.scroll(90000, 0)
                            })
        }
    },
    computed: {
        date_to_local () {
            if (this.date_to == null) return null
            const dateTo = this.date_to.split('-')
            let resultStr = dateTo[2] + '.' + dateTo[1]
            if (this.cdate[0] !== dateTo[0]) resultStr += '.' + dateTo[0].substring(2)
            return resultStr
        },
        date_from_local () {
            if (this.date_from == null) return null
            const dateFrom = this.date_from.split('-')
            let resultStr = dateFrom[2] + '.' + dateFrom[1]
            if (this.cdate[0] !== dateFrom[0]) resultStr += '.' + dateFrom[0].substring(2)
            return resultStr
        },
        net_balance_diff () {
            let diff = 0
            if (this.dataYear === null) return
            for (let i = 0; i < this.dataYear.length; i++) {
                const incomesNumb = this.dataYear[i].incomes.summ.raw
                const expensesNumb = this.dataYear[i].expenses.summ.raw
                diff += incomesNumb - expensesNumb
            }
            return diff
        },
        net_balance () {
            var result = new Intl.NumberFormat('fr-FR').format(this.net_balance_diff)
            if (this.net_balance_diff > 0) result = '+' + result
            return result
        },
        net_balance_percent () {
            let nbp = 0
            if (this.incomes?.summ != null) {
                nbp = this.net_balance_diff * 100 / this.incomes.summ.raw
            }
            return nbp
        }
    },
    mounted () {
        const now = new Date()
        const cdate = new Date(now.getTime() + 360 * 60000).toJSON().slice(0, 10)
        this.cdate = cdate.split('-')
        this.date_from = this.cdate[0] + '-' + this.cdate[1] + '-01'
        this.date_to = this.cdate[0] + '-' + this.cdate[1] + '-' + this.cdate[2]
        this.getDataYear()
        // this.getData()
    }
}
</script>
<style scoped>
    .menu_open{
        height: 100%
    }
</style>
